<template>
  <div class="loginmodal" v-if="state !== 'ready'">
    <div class="modal-content agreeart">
      <p id="mainTM" class="modaltxt" v-if="type === 'header'"> <!--유저페이지에서-->
        <span v-if="state === 'ready'">게임 완전 종료 처리중입니다.<br> 잠시만(1분이내) 기다려주세요.</span>
        <span v-if="state === 'complete'">환전을 위한 유효성 검사가 진행중입니다.<br> 잠시만 기다려주세요.</span>
        <span v-if="state === 'fail'">통합머니로 전환 실패. 고객센터로 문의주세요</span>
      </p>
      <p id="exchTM" class="modaltxt" v-if="type === 'cash'"> <!--출금시-->
        <span>통합머니 처리가 완료되지 않았습니다.<br> 통합머니 전환 버튼을 클릭해주세요.</span>
      </p>
      <p id="partnerTM" class="modaltxt" v-if="type === 'partner'"> <!--파트너페이지에서-->
        <span>해당회원의 통합머니 전환이 이루어지지 않았습니다.<br> 전환진행을 부탁드립니다.</span>
      </p>
      <button class="yes" @click="$emit('close')">확인</button>
    </div>
  </div>
</template>

<script>
import { setMoneyMerge, setMoneyMergeForPartner } from '@/api/member'

export default {
  name: 'TMmodal',
  props: [
    'type',
    'isOpen',
    'userData'
  ],
  data () {
    return {
      state: 'ready'
    }
  },
  watch: {
    async isOpen () {
      if (this.isOpen) {
        if (this.type !== 'header') {
          this.state = 'ing'
        }
        await this.moneyMerge()
      }
    }
  },
  methods: {
    autoClose () {
      if (this.state === 'complete') {
        setTimeout(() => {
          this.$emit('close')
          this.state = 'ready'
        }, 1800)
      }
    },
    async moneyMerge () {
      console.log('moneyMerge')
      const param = {}
      if (this.userData) {
        param.memId = this.userData.memId
      }
      console.log(param)

      if (this.type === 'header') {
        await setMoneyMerge(param).then(async res => {
          console.log(res)
          const result = res.data
          if (result.resultCode === '0') {
            this.state = 'complete'
          } else if (result.resultCode === 'C009') {
            this.$emit('cancel')
            await this.onAlert('warningart', result.resultMessage)
          } else {
            this.$emit('cancel')
            this.state = 'fail'
          }
        })
      } else {
        await setMoneyMergeForPartner(param).then(async res => {
          console.log(param, res)
          const result = res.data
          if (result.resultCode === '0') {
            this.state = 'complete'
          } else if (result.resultCode === 'C009') {
            await this.onAlert('warningart', result.resultMessage)
            this.$emit('close')
          } else {
            this.state = 'fail'
          }
        })
      }
    }
  },
  updated () {
    this.autoClose()
  }
}
</script>

<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>

<style scoped>
.loginmodal {position: fixed;z-index: 999;left: 0;top: 0;width: 100%;height: 100%;overflow-y: hidden;background-color: rgb(0,0,0);background-color: rgba(0,0,0,0.8);display: flex;justify-content: center;align-items: center;}
.modal-content {width: 400px; height:262px; margin: 100px auto 0;padding:40px 20px;text-align: center;box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);border: solid 1px #333;border-radius: 38px;background: #141414; color:#fff; display: flex; flex-direction: column; gap: 35px; align-items: center;position: relative;}
.modaltxt{margin-top:0;text-align: left;font-size:18px;line-height: 2;display: flex;flex-direction: column; text-align: center;font-size: 16px;}
/* br {display: none;} */
.agreeart .yes {
  color: #fff;
  background-image: linear-gradient(to bottom, #e5972d, #784f16);
  box-sizing: border-box;
  /* width: 50%; */
  padding: 10px 20px;
  font-size: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  background: none;
  border: 0;
  color: #fff;
  font-size: 25px;
}
.art .img {display: block;margin: 0 auto;margin-bottom: 30px;}
.agreeart .img {width: 90px;height: 65px;background: url('~@/assets/img/agreeart.svg')no-repeat;background-position: center;}
.loadingCircle {
  width: 40px;
  height: 40px;
  margin: 10px auto;
  border: 10px solid #fff;
  border-bottom: 10px solid #363636;
  border-radius: 50%;
  animation: load 1.5s linear infinite;
}
@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 700px) {
  .modal-content {margin: 50% auto; max-width: 90%; box-sizing: border-box;}
  .loginclose {top:20px; right: 20px;}
  .modaltxt {font-size: 14px;white-space: nowrap;}
  br {display: block;}
  .closeBtn {
    top: 5px;
  }
}
</style>
